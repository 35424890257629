<template>
	<div class="divBg" v-if="openNote" @click="updateNote()"></div>
	<div
		v-if="setEditNoteWin"
		class="EditWinOverlay"
		@click="
			resetNoteModel();
			addNote = false;
			setEditNoteWin = false;
		"
	></div>

	<TagLeftBar
		:showSourceBar="showSourceBar"
		@openAddTagDialog="openAddTagDialog"
		@rerenderPage="initNoteData"
		@manageKnowledge="manageKnowledgeFunc"
		:knowledgePoints="knowledgePoints"
		:searchPoints="searchPoints"
		:searchTags="searchTags"
		:searchAll="searchAll"
		@update:searchPoints="searchPoints = $event"
		@update:searchTags="searchTags = $event"
		@update:searchAll="searchAll = $event"
	/>

	<!-- <TagLeftBar
		@openAddTagDialog="openAddTagDialog"
		@rerenderPage="initNoteData"
		@manageKnowledge="manageKnowledgeFunc"
		:knowledgePoints="knowledgePoints"
		:searchPoints="searchPoints"
		:searchTags="searchTags"
		:searchAll="searchAll"
		@update:searchPoints="searchPoints = $event"
		@update:searchTags="searchTags = $event"
		@update:searchAll="searchAll = $event"
	/> -->

	<!-- 企業版 不用往左推 tab層已推 -->
	<div :class="{ leftMargin: !isEnterpriseView && !$store.getters.mobileSize }">
		<transition name="slide-up" appear>
			<div
				class="talkArea"
				v-show="showTalkWindow"
				:style="{
					left: $store.getters.mobileSize ? '0px' : '250px',
					'padding-bottom': $store.getters.mobileSize ? '180px' : '112px',
				}"
			>
				<div ref="messageContainer" class="messageRow">
					<el-button
						v-if="!noneHistory"
						type="info"
						size="small"
						plain
						@click="loadHistory()"
						>顯示較早的對話</el-button
					>
					<template v-for="i in messageList" :key="i">
						<div :class="{ 'text-end': i.send, 'text-start': !i.send }">
							<span class="messagePopup" :class="{ 'bg-dark': !i.send }">
								<p class="pre-wrap" v-html="i.content"></p>

								<div v-if="!i.send" class="mt-3 d-flex">
									<el-button link
										><i
											class="fa-light fa-copy fa-lg"
											@click="copyResponse(i)"
										></i
									></el-button>
									<template v-if="!i.isHistory">
										<el-button link @click="isHelpful(true, i)"
											><i class="fa-light fa-thumbs-up fa-lg"></i
										></el-button>
										<el-button link class="me-4" @click="isHelpful(false, i)"
											><i
												class="fa-light fa-thumbs-down fa-flip-horizontal fa-lg"
											></i
										></el-button>
									</template>

									<el-button
										class="ms-auto"
										type="info"
										round
										@click="addNoteFunc_withOutselectTagId(i)"
										:disabled="i.isSaved"
										><i class="fas fa-thumbtack fa-flip-horizontal me-2"></i>
										<span>{{ i.isSaved ? "已儲存" : "儲存" }}</span></el-button
									>
								</div>
							</span>
						</div>
					</template>
				</div>
			</div>
		</transition>
		<el-row
			:gutter="20"
			style="margin-right: 0; margin-left: 0; padding-bottom: 130px"
			@mouseover="$store.state.LeftBarContentShow = false"
		>
			<el-col :lg="20" :md="24" class="mx-auto">
				<template
					v-if="
						$store.state.isSelectKnowledge &&
						$store.state.selectTagId != 'All' &&
						$store.state.selectTagId != 'trash' &&
						$store.state.selectTagId.length != 0
					"
				>
					<div class="text-start h5 tagsArea d-flex">
						<h3 class="text-start h5">包含的標籤 :</h3>
						<el-tag
							v-for="i in usersTags.filter((x) =>
								$store.state.selectTagId.includes(x.id)
							)"
							:key="i"
							>{{ i.name }}
						</el-tag>
					</div>
				</template>
				<div
					v-if="$store.state.selectTagId != 'trash'"
					class="AddNoteArea mb-2 main_card mx-2 overflow-hidden"
					:class="{
						EditWin: setEditNoteWin,
						focusWarning: focusWarning,
						'mx-auto': setEditNoteWin,
						'ms-auto': !setEditNoteWin,
						'w-100': $store.getters.mobileSize,
						'bg-white': addNote,
					}"
					style="border-radius: 8px"
					:style="{
						'max-width':
							setEditNoteWin && $store.getters.mobileSize
								? '95vw'
								: filteredData.length == 0
								? ''
								: '800px',
						overflow: 'inherit',
						border: !setEditNoteWin ? 'none' : '',
						'border-top':
							addNoteInfo.bgColor != '' && setEditNoteWin
								? `5px  solid  ${addNoteInfo.bgColor}`
								: 'none',
					}"
				>
					<div v-if="addNote">
						<div class="text-start p-3">
							<div class="d-flex align-items-center mb-4">
								<input
									class="w-100 AddNoteTitleInput"
									type="text"
									placeholder="標題..."
									v-model="addNoteInfo.title"
									@blur="updateNote"
								/>
								<el-tooltip
									effect="dark"
									content="複製整篇記事文字"
									placement="bottom"
								>
									<span
										@click="copyText(addNoteInfo)"
										class="NoteBtn NoteBtn-sm ms-2 me-2"
									>
										<i class="fal fa-copy"></i>
									</span>
								</el-tooltip>
							</div>
							<div>
								<div v-if="!addNoteInfo.isCloudImport">
									<div class="AddNoteTitle">
										<template v-for="(t, i) in addNoteInfo.todoItems" :key="t">
											<div
												class="pe-2 py-1 pb-3"
												:class="{
													'border-bottom':
														i !== addNoteInfo.todoItems.length - 1,
												}"
											>
												<div class="d-flex align-items-center">
													<!-- <el-checkbox
													style="align-self: flex-start"
													name="type"
													v-model="t.checked"
													@change="updateNoteTodo(t)"
												/> -->
													<el-input
														:class="{ mobile: $store.getters.mobileSize }"
														maxlength="1000"
														resize="none"
														autosize
														type="textarea"
														class="mx-2"
														:id="'todo_' + t.noteTodoId"
														v-model="t.title"
														@blur="updateNoteTodo(t)"
													/>

													<div class="d-flex" style="align-self: flex-start">
														<el-tooltip
															effect="dark"
															content="刪除"
															placement="bottom"
														>
															<span
																@click="deleteTodoItem(t)"
																class="NoteBtn NoteBtn-sm me-1"
															>
																<i class="fal fa-times"></i>
															</span>
														</el-tooltip>
														<el-tooltip
															class="d-none"
															effect="dark"
															content="詳細編輯"
															placement="bottom"
														>
															<span
																@click="setTodoItemDialog(t)"
																class="NoteBtn NoteBtn-sm d-none"
															>
																<i class="fal fa-angle-right"></i>
															</span>
														</el-tooltip>
													</div>
												</div>
												<el-upload
													class="mx-2"
													action="https://jsonplaceholder.typicode.com/posts/"
													:on-preview="handleNotePreview"
													:on-remove="
														(file) => {
															todoItemDialog.id = t.noteTodoId;
															handleNoteRemove(file);
														}
													"
													:http-request="
														(file) => {
															todoItemDialog.id = t.noteTodoId;
															uploadFile(file);
														}
													"
													:file-list="t.fileInfos"
													accept=".jpg,.png"
													list-type="picture-card"
												>
													<el-button size="small" type="primary" link
														><i
															class="fa-duotone fa-solid fa-arrow-up-from-bracket me-1"
														></i>
														<span>圖片</span></el-button
													>
													<!-- <div class="el-upload__tip mt-0">
														只能上傳jpg/png文件
													</div> -->
												</el-upload>
											</div>
										</template>
									</div>
									<div class="d-flex align-items-center mb-2 NoteCheckBox">
										<i class="fal fa-plus me-2"></i>
										<el-input
											class="w-100 AddNoteTitleInput"
											type="textarea"
											resize="none"
											placeholder="清單項目"
											autosize
											@click="inputFirst"
											@input="inputFirst"
											id="createNote"
											v-model="noteTitleInput"
										/>
									</div>
								</div>
								<div
									v-else
									style="overflow: auto; max-height: 50vh; padding-right: 1rem"
								>
									<li
										class="border-top list-group-item p-2 d-flex align-items-center justify-content-between"
										v-if="addNoteInfo.cloudFolderId != ''"
									>
										<div>
											<span
												><i
													class="fa-solid fa-folders"
													style="color: #3b80b5"
												></i
											></span>
											<el-link
												@click.stop
												class="ms-2 wrap-text"
												type="primary"
												:href="addNoteInfo.cloudFolderUrl"
												target="_blank"
												>{{
													truncateText(addNoteInfo.cloudFolderName)
												}}</el-link
											>
										</div>
										<el-tooltip effect="dark" content="刪除" placement="bottom">
											<span
												@click="deleteImportFile()"
												class="NoteBtn NoteBtn-sm me-1"
											>
												<i class="fal fa-times"></i>
											</span>
										</el-tooltip>
									</li>
									<li
										class="border-top list-group-item p-2 d-flex align-items-center justify-content-between"
										v-for="item in filteredCloudImports(addNoteInfo.id)"
										:key="item"
									>
										<div class="d-flex">
											<i
												class="fa-solid fa-file"
												style="color: #3b80b5; align-self: flex-start"
											></i>
											<el-link
												class="ms-2 wrap-text"
												@click.stop
												type="primary"
												:href="item.url"
												target="_blank"
												>{{ truncateText(item.fileName, 200) }}</el-link
											>
										</div>
										<span
											v-if="addNoteInfo.cloudFolderId == ''"
											@click="deleteImportFile(item)"
											class="NoteBtn NoteBtn-sm me-1"
										>
											<i class="fal fa-times"></i>
										</span>
									</li>
								</div>
							</div>
							<div v-if="!assigneeNote">
								<el-avatar
									class="me-1 border"
									v-for="a in addNoteInfo.assignee"
									:title="a.email"
									:key="a"
									size="mini"
									:src="a.avatar"
								></el-avatar>
							</div>
							<div
								class="tagsArea text-start"
								v-if="addNoteInfo.tags != null && addNoteInfo.tags != undefined"
							>
								<el-tag
									v-for="item in addNoteInfo.tags"
									:key="item"
									size="small"
								>
									{{ getUserTagsName(item) }}
								</el-tag>
							</div>
						</div>
						<div v-if="assigneeNote" class="text-start px-2 pb-2">
							<div class="border p-2 rounded bg-light">
								<h3 class="mb-1 pb-1 border-bottom">協作者</h3>
								<ul class="list-group list-group-flush">
									<li
										v-for="item in addNoteInfo.assignee"
										:key="item"
										class="list-group-item text-start d-flex align-items-center justify-content-between px-1 bg-light"
									>
										<div class="d-flex align-items-center w-100">
											<input type="hidden" :value="item.id" />
											<el-avatar
												size="medium"
												:src="item.avatar"
												class="me-1"
											></el-avatar>
											<div class="me-auto">
												<p class="ms-3">{{ item.name }}</p>
												<p class="ms-3">{{ item.email }}</p>
											</div>
											<span
												@click="
													deleteTodoAssignee(item);
													updateNote();
												"
												class="NoteBtn NoteBtn-sm"
											>
												<i class="fal fa-times"></i>
											</span>
										</div>
									</li>
								</ul>
								<div class="mt-1 d-flex align-items-center">
									<!-- <el-button class="me-1" @click="autocomplete_selectAll">全部加入</el-button> -->
									<el-autocomplete
										popper-class="my-autocomplete"
										:disabled="isReadOnlySelectedNoteAssignee"
										class="inline-input me-2"
										:fetch-suggestions="querySearchByClassMember"
										v-model="noteAssigneeInput"
										placeholder="請輸入帳號"
										:trigger-on-focus="false"
										@select="handleSelectNoteAssignee"
									>
										<template v-slot="{ item }">
											<div class="w-100 d-flex align-items-center py-2">
												<el-avatar size="small" :src="item.avatar" />
												<div class="ms-2">
													<div style="line-height: normal">
														<span
															style="font-size: x-small"
															v-if="item.role.toLowerCase() == 'teacher'"
															>老師</span
														>
														<span
															style="font-size: x-small"
															v-if="item.role.toLowerCase() == 'student'"
															>學生</span
														>
														<span
															style="font-size: x-small"
															v-if="item.role.toLowerCase() == 'parent'"
															>家長</span
														>
														<span class="d-block">{{ item.name }}</span>

														<span>{{ item.email }}</span>
													</div>
												</div>
											</div>
										</template>
									</el-autocomplete>
									<el-button
										size="small"
										type="danger"
										plain
										@click="cancelNoteAutoComplete"
										>取消</el-button
									>
									<el-button
										:disabled="selectedNoteAssignee == null"
										size="small"
										type="primary"
										plain
										@click="
											setNoteAssignee();
											updateNote();
										"
										>加入</el-button
									>
								</div>
							</div>
						</div>

						<div
							v-if="!assigneeNote"
							class="d-flex align-items-center note_btn_area px-3"
						>
							<el-tooltip
								v-if="!isEnterpriseView"
								effect="dark"
								content="協作者"
								placement="bottom"
							>
								<span
									class="NoteBtn NoteBtn-sm me-1"
									@click="assigneeNote = true"
								>
									<i class="fal fa-user-plus"></i>
								</span>
							</el-tooltip>
							<el-tooltip effect="dark" content="標籤" placement="bottom">
								<span
									class="NoteBtn NoteBtn-sm me-1"
									@click="
										selectNote = addNoteInfo;
										dialogAddTag = true;
									"
								>
									<i class="fal fa-tag"></i>
								</span>
							</el-tooltip>
							<!-- <el-tooltip effect="dark" content="作為喬米秘書GPT資料回答依據" placement="bottom">
              <span
                :class="{ isDatabaseUsed: addNoteInfo.isDatabaseUsed }"
                class="NoteBtn NoteBtn-sm me-1"
                @click="
                  addNoteInfo.isDatabaseUsed = !addNoteInfo.isDatabaseUsed;
                  updateNote();
                "
              >
                <i class="fal fa-sparkle"></i>
              </span>
            </el-tooltip> -->
							<el-tooltip
								effect="dark"
								content="匯入Google檔案"
								placement="bottom"
							>
								<span
									class="NoteBtn NoteBtn-sm me-1"
									@click="openImportDialog()"
								>
									<i class="fal fa-brands fa-google-drive"></i>
								</span>
							</el-tooltip>
							<el-tooltip
								v-if="addNoteInfo.isCloudImport"
								effect="dark"
								content="重讀Google檔案"
								placement="bottom"
							>
								<span
									class="NoteBtn NoteBtn-sm me-1"
									@click="reloadGoogleFiles()"
								>
									<i class="fa-solid fa-rotate"></i>
								</span>
							</el-tooltip>
							<el-dropdown trigger="click">
								<span class="el-dropdown-link">
									<i class="fal fa-angle-down NoteBtn NoteBtn-sm"></i>
								</span>
								<template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item>
											<el-dropdown trigger="click">
												<span class="el-dropdown-link"> 背景顏色 </span>
												<template #dropdown>
													<el-dropdown-menu>
														<el-dropdown-item
															v-for="i in predefineColors"
															:key="i"
															@click="
																addNoteInfo.bgColor = i;
																updateNote();
																openNote = false;
															"
														>
															<div
																class="colorCircle"
																:style="{ 'background-color': i }"
															></div>
														</el-dropdown-item>
													</el-dropdown-menu>
												</template> </el-dropdown
										></el-dropdown-item>
										<el-dropdown-item @click.stop="deleteNote(addNoteInfo)"
											>刪除</el-dropdown-item
										>
									</el-dropdown-menu>
								</template>
							</el-dropdown>
						</div>
					</div>
					<div v-else>
						<div v-if="filteredData.length == 0" @click="addNoteFunc">
							<el-card
								v-if="!$store.state.isSelectKnowledge"
								class="m-1"
								:body-style="{
									padding: '40px 0',
									width: '100%',
								}"
								shadow="hover"
							>
								<i
									class="fad fa-plus-circle"
									style="
										--fa-primary-color: #ffffff;
										--fa-secondary-color: #f7e6b2;
										--fa-secondary-opacity: 1;
										font-size: 40px !important;
									"
								></i>
								<p class="mt-3 fw-bold" style="color: rgb(81, 72, 50)">
									{{ isEnterpriseView ? "新增資料" : "新增記事" }}
								</p>
							</el-card>
						</div>
						<div v-else class="d-flex align-items-center justify-content-end">
							<el-button
								v-if="!$store.state.isSelectKnowledge"
								@click="addNoteFunc"
								type="info"
								plain
								round
								><i class="fal fa-plus me-2"></i>
								<span>{{ isEnterpriseView ? "新增資料" : "新增記事" }}</span>
							</el-button>
						</div>
					</div>
				</div>
				<el-row :gutter="20" class="mt-3">
					<template v-for="o in filteredData" :key="o">
						<el-col :lg="8" :md="12" :xs="24">
							<el-card
								:style="{
									'border-top':
										o.backgroundColor != ''
											? `5px solid ${o.backgroundColor} `
											: '',
								}"
								style="border-radius: 8px"
								:body-style="{ padding: '0px' }"
								:id="'note_' + o.id"
								class="mb-3 p-3"
								@click="
									$store.state.selectTagId != 'trash' ? setEditNote(o) : null
								"
							>
								<div class="text-start">
									<div class="d-flex justify-content-between align-items-start">
										<h3
											class="pb-2 px-2"
											style="font-weight: 600; font-size: 18px"
										>
											{{ o.title == "" ? "未輸入標題" : o.title }}
										</h3>
										<div class="d-flex align-items-center noteTopSection">
											<!-- <div class="me-2" v-if="o.isDatabaseUsed">
                      <i class="fal fa-sparkle"></i>
                    </div> -->
											<div
												v-if="!o.isTop"
												class="pb-2"
												@click.stop="topNote(o)"
											>
												<i class="fas fa-thumbtack"></i>
											</div>
											<div v-else class="pb-2" @click.stop="topNote(o)">
												<i class="fal fa-thumbtack"></i>
											</div>
										</div>
									</div>
									<div>
										<ul class="m-0 p-0" v-if="!o.isCloudImport">
											<li
												class="border-top list-group-item p-2 d-flex align-items-center"
												v-for="i in cardLimitedItems(o.todoItems)"
												:key="i"
											>
												<!-- <el-checkbox
													style="align-self: flex-start"
													name="type"
													class="me-2"
													v-model="i.checked"
													disabled
												/> -->
												<p class="wrap-text">
													{{ truncateText(i.title, 200) }}
												</p>
											</li>
											<p
												v-if="cardLimitedItemsCount(o.todoItems)"
												class="px-2"
												style="font-size: 24px"
											>
												...
											</p>
										</ul>
										<ul class="m-0 p-0" v-else>
											<li
												class="border-top list-group-item p-2 d-flex align-items-center"
												v-if="o.cloudFolderId != ''"
											>
												<span
													><i
														class="fa-solid fa-folders"
														style="color: #3b80b5"
													></i
												></span>
												<el-link
													@click.stop
													class="ms-2 wrap-text"
													type="primary"
													:href="o.cloudFolderUrl"
													target="_blank"
													>{{ truncateText(o.cloudFolderName) }}</el-link
												>
											</li>
											<li
												class="border-top list-group-item p-2 d-flex align-items-center"
												v-for="item in cardLimitedItems(
													filteredCloudImports(o.id)
												)"
												:key="item"
											>
												<i
													class="fa-solid fa-file"
													style="color: #3b80b5; align-self: flex-start"
												></i>
												<el-link
													class="ms-2 wrap-text"
													@click.stop
													type="primary"
													:href="item.url"
													target="_blank"
													>{{ truncateText(item.fileName) }}</el-link
												>
											</li>
											<p
												v-if="cardLimitedItemsCount(filteredCloudImports(o.id))"
												class="px-2"
												style="font-size: 24px"
											>
												...
											</p>
										</ul>
									</div>
								</div>
								<div
									v-if="o.assignees != undefined && o.assignees != null"
									class="d-flex justify-content-start"
								>
									<el-avatar
										class="m-1 border"
										:title="a.email"
										v-for="a in o.assignees"
										:key="a"
										size="mini"
										:src="a.avatar"
									></el-avatar>
								</div>
								<div class="d-flex flex-wrap ms-2">
									<template v-for="m in collectionsImage(o)" :key="m">
										<div>
											<el-image
												style="width: 80px; height: 80px"
												:src="m.url"
											></el-image>
										</div>
									</template>
								</div>

								<template
									v-if="
										o.tags != null && o.tags != undefined && o.tags.length > 0
									"
								>
									<div class="tagsArea text-start">
										<el-tag v-for="item in o.tags" :key="item" size="small">
											{{ getUserTagsName(item) }}
										</el-tag>
									</div>
								</template>

								<div v-if="$store.state.selectTagId == 'trash'">
									<div
										class="p-2 d-flex justify-content-end"
										style="font-size: xx-small"
									>
										<el-button circle title="復原" @click="undoNote(o)">
											<i class="fal fa-trash-undo"></i
										></el-button>
										<el-button
											circle
											title="永久刪除"
											@click="deletePermanently(o)"
										>
											<i class="fal fa-trash"></i
										></el-button>
									</div>
								</div>
							</el-card>
						</el-col>
					</template>
				</el-row>
			</el-col>
		</el-row>
	</div>

	<div
		class="messageBox"
		:class="{ messageBoxMobile: $store.getters.mobileSize }"
	>
		<div
			class="text-end d-flex align-items-center justify-content-between mb-2"
		>
			<div v-if="$store.getters.mobileSize">
				<el-badge
					type="success "
					:value="[...searchPoints, ...searchTags, ...searchAll].length"
					class="item"
				>
					<el-button
						type="primary"
						@click="
							showSourceBar = true;
							showTalkWindow = false;
						"
						text
						bg
						>來源
					</el-button>
				</el-badge>

				<el-button
					class="ms-2"
					type="primary"
					@click="
						showSourceBar = false;
						showTalkWindow = false;
					"
					text
					bg
					>{{ isEnterpriseView ? "資料" : "記事本" }}</el-button
				>
			</div>
			<el-tooltip effect="dark" content="關閉對話" placement="bottom">
				<el-button
					v-if="$store.getters.mobileSize"
					type="info"
					plain
					circle
					@click="toggleTalkArea()"
				>
					<i
						class="far fa-chevron-up"
						:class="{ 'fa-chevron-down': showTalkWindow }"
					></i>
				</el-button>
			</el-tooltip>
		</div>

		<div
			class="d-flex align-items-center"
			:class="{ 'justify-content-between': !$store.getters.mobileSize }"
		>
			<el-button
				v-if="!$store.getters.mobileSize"
				type="text"
				@click="toggleTalkArea()"
				>關閉對話</el-button
			>
			<div style="width: 100%" :class="{ ' ms-3': !$store.getters.mobileSize }">
				<div v-if="imageUrl" class="image-preview">
					<el-button class="deleteImg" circle @click="imageUrl = ''">
						<i class="fal fa-times"></i
					></el-button>
					<img class="w-100" :src="imageUrl" alt="Pasted Image" />
				</div>
				<div class="message-main">
					<div
						class="px-2"
						style="width: 15%; font-size: xx-small"
						v-if="!$store.getters.mobileSize"
					>
						{{ [...searchPoints, ...searchTags, ...searchAll].length }}個來源
					</div>
					<div
						class="message-input"
						:class="{ 'w-100': $store.getters.mobileSize }"
						style="width: 85%"
					>
						<el-input
							placeholder="請輸入內容..."
							type="textarea"
							class="message-input-textarea w-100"
							name=""
							id=""
							autosize
							resize="none"
							@paste="handlePaste"
							@keydown.enter="sendMessage"
							v-model="messageInput"
						/>
						<el-button class="ms-2 fs-3" link @click="sendMessage"
							><i class="fad fa-arrow-circle-up fa-lg"></i
						></el-button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="dialog noteDialog">
		<el-dialog
			v-model="NoteInfoDialog"
			:width="$store.getters.mobileSize ? '90%' : '600px'"
			:show-close="false"
			@close="saveTodoItemContentById(todoItemDialog.id)"
			@open="getTodoItemById(todoItemDialog.id)"
		>
			<template v-slot:header>
				<div class="d-flex align-items-center justify-content-between">
					<span>{{ truncateText(todoItemDialog.title, 100) }}</span>
					<el-button
						style="width: 30px; height: 30px"
						circle
						@click="NoteInfoDialog = false"
						><i class="fal fa-times"></i
					></el-button>
				</div>
			</template>
			<div class="">
				<div class="edit_space mb-2">
					<Editor
						class="shadow"
						style="
							border-radius: 8px;
							overflow: hidden;
							border: 1px rgba(0, 0, 0, 0.15) solid;
						"
						:value="todoItemDialog.content"
						@input="getEditorDataTodoContent"
					/>
				</div>
				<el-upload
					style="
						border-radius: 8px;
						overflow: hidden;
						border: 1px rgba(0, 0, 0, 0.15) solid;
					"
					class="upload-demo shadow"
					action="https://jsonplaceholder.typicode.com/posts/"
					:show-file-list="false"
					drag
					multiple
					:http-request="uploadFile"
					:on-preview="handleNotePreview"
					:on-remove="handleNoteRemove"
					list-type="text"
				>
					<i
						class="fa-duotone fa-cloud-arrow-up mb-1"
						style="
							--fa-primary-color: #ffffff;
							--fa-secondary-color: #0066ff;
							font-size: 4rem;
						"
					></i>
					<div class="el-upload__text">
						將文件拖曳到此處，或<em>點擊上傳</em>
					</div>
					<div class="el-upload__tip" :v-slot="tip">檔案不超過10MB</div>
				</el-upload>
				<div
					class="mt-2 py-1 px-2 d-flex justify-content-between align-items-center"
					v-for="item in todoItemDialog.fileList"
					:key="item"
				>
					<div>
						<i
							class="fad fa-file me-2"
							style="
								--fa-primary-color: #398d69;
								--fa-secondary-color: #9cbdab;
								--fa-secondary-opacity: 1;
							"
						></i>
						<el-link @click="handleNotePreview(item)">{{ item.name }}</el-link>
					</div>
					<div>
						<i
							class="fa-regular fa-folder-arrow-down downloadFileIcon me-3"
							@click="handleNoteDownload(item)"
						></i>
						<i
							class="fa-regular fa-circle-xmark removeFileIcon"
							@click="handleNoteRemove(item)"
						></i>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog
			v-model="dialogAddTag"
			title="新增標籤"
			width="80%"
			@open="dialogAddTagOpen"
		>
			<el-form :model="tagForm">
				<el-form-item label="名稱">
					<el-input
						v-model="tagForm.name"
						autocomplete="off"
						placeholder="請輸入標籤名稱"
					/>
					<div class="mt-1">
						<el-button type="primary" size="small" @click="addTag(tagForm.name)"
							>新增標籤</el-button
						>
					</div>
				</el-form-item>
				<el-form-item label="標籤">
					<el-checkbox-group
						style="text-align: start"
						v-model="tagForm.selected"
						@change="selectTag"
					>
						<el-checkbox
							v-for="item in usersTags"
							:key="item.id"
							:label="item.id"
							>{{ item.name }}</el-checkbox
						>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>

	<div class="dialog knowledgePointDialog">
		<el-dialog
			v-model="knowledgeDialog"
			:width="$store.getters.mobileSize ? '90%' : '800px'"
			:show-close="false"
			@close="closeKnowledge()"
		>
			<template v-slot:header>
				<div class="d-flex align-items-center justify-content-between">
					<p class="fw-bold">知識點管理</p>
					<div>
						<el-button round type="primary" @click="addKnowledge"
							><i class="fal fa-plus-circle me-2"></i>
							<span>新增知識點</span></el-button
						>
						<el-button
							style="width: 30px; height: 30px"
							circle
							@click="knowledgeDialog = false"
							><i class="fal fa-times"></i
						></el-button>
					</div>
				</div>
			</template>
			<div class="knowledgePointDialogCollapse">
				<el-collapse>
					<el-collapse-item
						v-for="(point, key, index) in knowledgePoints"
						:key="key"
						:title="key"
						:name="index"
					>
						<template #title>
							<div class="d-flex justify-content-between w-100">
								<p>{{ point.title }}</p>
								<div class="d-flex align-items-center">
									<el-tooltip
										effect="dark"
										content="共享知識點"
										placement="bottom"
									>
										<el-button
											class="me-2"
											:class="{ isPublic: point.isPublic }"
											style="width: 25px; height: 25px"
											circle
											@click.stop.prevent="setKnowledgePointPublic(point)"
											><i class="fal fa-share-nodes"></i
										></el-button>
									</el-tooltip>
									<el-tooltip
										effect="dark"
										content="新增分享者"
										placement="bottom"
									>
										<el-button
											class="me-2 ms-0"
											style="width: 25px; height: 25px"
											circle
											@click.stop.prevent="
												setKnowledgePointObject(point, 'Share')
											"
											><i class="fal fa-users"></i
										></el-button>
									</el-tooltip>
									<el-tooltip
										effect="dark"
										content="新增標籤"
										placement="bottom"
									>
										<el-button
											class="me-2 ms-0"
											style="width: 25px; height: 25px"
											circle
											@click.stop.prevent="
												setKnowledgePointObject(point, 'Tag')
											"
											><i class="fal fa-tag"></i
										></el-button>
									</el-tooltip>
									<el-tooltip effect="dark" content="更多" placement="bottom">
										<div class="me-2">
											<el-dropdown trigger="click" @click.stop.prevent>
												<span class="el-dropdown-link" @click.stop.prevent>
													<i
														style="width: 25px; height: 25px"
														class="fal fa-ellipsis-vertical NoteBtn NoteBtn-sm"
													></i>
												</span>
												<template #dropdown>
													<el-dropdown-menu>
														<el-dropdown-item
															@click="renameKnowledgePoint(point)"
															>編輯名稱</el-dropdown-item
														>
														<el-dropdown-item
															@click="deleteKnowledgePoint(point.id)"
															>刪除</el-dropdown-item
														>
													</el-dropdown-menu>
												</template>
											</el-dropdown>
										</div>
									</el-tooltip>
								</div>
							</div>
						</template>
						<div class="tagsArea text-start">
							<el-tag v-for="id in point.tagId" :key="id" size="small">
								{{ getUserTagsName(id) }}
							</el-tag>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</el-dialog>

		<el-dialog
			v-model="dialogAddKnowledgePointTag"
			title="新增標籤"
			:width="$store.getters.mobileSize ? '80%' : '600px'"
		>
			<el-form :model="knowledgePointTagForm">
				<el-form-item label="名稱">
					<el-input
						v-model="knowledgePointTagForm.name"
						autocomplete="off"
						placeholder="請輸入標籤名稱"
					/>
					<div class="mt-1">
						<el-button
							type="primary"
							size="small"
							@click="addTag(knowledgePointTagForm.name)"
							>新增標籤</el-button
						>
					</div>
				</el-form-item>
				<el-form-item label="標籤">
					<el-checkbox-group
						style="text-align: start"
						v-model="knowledgePointTagForm.selected"
						@change="selectKnowledgePointTag()"
					>
						<el-checkbox
							v-for="item in usersTags"
							:key="item.id"
							:label="item.id"
							>{{ item.name }}</el-checkbox
						>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog
			v-model="dialogAddKnowledgePointShare"
			title="新增分享者"
			:width="$store.getters.mobileSize ? '80%' : '600px'"
			@close="cancelKnowledgeAssignee()"
		>
			<el-tabs
				v-model="dialogAddKnowledgePointShareActiveName"
				@tab-click="handleClick"
				stretch="true"
			>
				<el-tab-pane label="個人" name="first">
					<div class="mt-1 mb-3 d-flex align-items-center">
						<el-autocomplete
							popper-class="my-autocomplete"
							:disabled="isReadOnlySelectedKnowledgeAssignee"
							class="inline-input me-2 w-100"
							:fetch-suggestions="querySearchByClassMember"
							v-model="knowledgeAssigneeInput"
							placeholder="請輸入帳號"
							:trigger-on-focus="false"
							@select="handleSelectKnowledgeAssignee"
						>
							<template v-slot="{ item }">
								<div class="w-100 d-flex align-items-center py-2">
									<el-avatar size="small" :src="item.avatar" />
									<div class="ms-2">
										<div style="line-height: normal">
											<span
												style="font-size: x-small"
												v-if="item.role.toLowerCase() == 'teacher'"
												>老師</span
											>
											<span
												style="font-size: x-small"
												v-if="item.role.toLowerCase() == 'student'"
												>學生</span
											>
											<span
												style="font-size: x-small"
												v-if="item.role.toLowerCase() == 'parent'"
												>家長</span
											>
											<span class="d-block">{{ item.name }}</span>
											<span>{{ item.email }}</span>
										</div>
									</div>
								</div>
							</template>
						</el-autocomplete>
						<el-button
							size="small"
							type="danger"
							plain
							@click="cancelKnowledgeAutoComplete"
							>取消</el-button
						>
						<el-button
							:disabled="selectedKnowledgeAssignee == null"
							size="small"
							type="primary"
							plain
							@click="
								setKnowledgeAssignee();
								updateKnowledgeAssignee();
							"
							>加入</el-button
						>
					</div>
					<ul
						class="list-group list-group-flush"
						style="max-height: 45vh; overflow: auto"
					>
						<li
							v-for="item in knowledgeAssignee"
							:key="item"
							class="list-group-item text-start d-flex align-items-center justify-content-between px-1 bg-light"
						>
							<div class="d-flex align-items-center w-100">
								<input type="hidden" :value="item.id" />
								<el-avatar
									size="medium"
									:src="item.avatar"
									class="me-1"
								></el-avatar>
								<div class="me-auto">
									<p class="ms-3">{{ item.name }}</p>
									<p class="ms-3">{{ item.email }}</p>
								</div>
								<span
									@click="
										deleteKnowledgeAssignee(item);
										updateKnowledgeAssignee();
									"
									class="NoteBtn NoteBtn-sm"
								>
									<i class="fal fa-times"></i>
								</span>
							</div>
						</li>
					</ul>
				</el-tab-pane>
				<el-tab-pane label="課程" name="second">
					<div
						class="d-flex flex-column"
						style="max-height: 45vh; overflow: auto"
					>
						<el-checkbox
							class="mb-2"
							v-for="item in knowledgeAssigneeClass"
							v-model="item.isChecked"
							:key="item"
							:label="item.name"
							size="large"
							@change="handleCheckKnowledgeChange(item)"
						/>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
	</div>

	<div class="dialog importDialog">
		<el-dialog
			v-model="dialogImport"
			title="Google檔案匯入"
			:width="$store.getters.mobileSize ? '90%' : '600px'"
			@close="cancelImport()"
		>
			<el-tabs
				v-model="dialogImportActiveName"
				@tab-click="handleClick"
				stretch="true"
			>
				<el-tab-pane label="文件" name="first">
					<div class="d-flex align-items-center">
						<el-input
							v-model="dialogImportFile"
							autocomplete="off"
							placeholder="請輸入Google文件路徑"
						/>
						<el-tooltip
							class="box-item"
							effect="dark"
							content="請先將文件設定為共用(存取權為知道連結的任何人)，再進行讀取"
							placement="bottom-end"
						>
							<div class="ms-2" circle>
								<i class="fa-solid fa-circle-question fa-xl"></i>
							</div>
						</el-tooltip>
						<el-button class="ms-2" @click="importGoogleFile">讀取</el-button>
						<el-button color="#626aef" @click="openGooglePicker(false)"
							><i class="fa-brands fa-google-drive"></i
						></el-button>
					</div>
					<div
						class="d-flex flex-column align-items-start my-3"
						style="min-height: 35vh; max-height: 35vh; overflow: auto"
					>
						<div
							v-for="item in dialogImportFileInfo"
							:key="item.id"
							class="my-2 d-flex justify-content-between align-items-center"
						>
							<i
								class="fal fa-trash-can"
								style="cursor: pointer; font-size: 16px"
								@click="removeCloudTempFile(item)"
							></i>
							<el-link
								class="ms-2 wrap-text"
								type="primary"
								:href="item.webViewLink"
								target="_blank"
								>{{ item.title }}</el-link
							>
						</div>
					</div>
					<div>
						<el-button class="w-25" type="danger" @click="cancelImport()"
							>取消</el-button
						>
						<el-button
							class="w-25"
							type="primary"
							@click="importAllGoogleFile(false)"
							>匯入</el-button
						>
					</div>
				</el-tab-pane>
				<el-tab-pane label="資料夾" name="second">
					<div class="d-flex align-items-center">
						<el-input
							v-model="dialogImportFolder"
							autocomplete="off"
							placeholder="請輸入Google資料夾路徑"
						/>
						<el-tooltip
							class="box-item"
							effect="dark"
							content="請先將資料夾設定為共用(存取權為知道連結的任何人)，再進行讀取"
							placement="bottom-end"
						>
							<div class="ms-2" circle>
								<i class="fa-solid fa-circle-question fa-xl"></i>
							</div>
						</el-tooltip>
						<el-button class="ms-2" @click="importGoogleFolder">讀取</el-button>
						<el-button color="#626aef" plain @click="openGooglePicker(true)"
							><i class="fa-brands fa-google-drive"></i
						></el-button>
					</div>
					<div
						class="d-flex flex-column align-items-start my-3"
						style="min-height: 35vh; max-height: 35vh; overflow: auto"
					>
						<div v-if="Object.keys(dialogImportFolderInfo).length != 0">
							<div class="d-flex align-items-center">
								<i
									class="fal fa-trash-can"
									style="cursor: pointer; font-size: 16px"
									@click="removeCloudTempFolder()"
								></i>
								<span style="font-size: 16px" class="ms-2">資料夾:</span>
								<el-link
									class="ms-2 wrap-text"
									type="primary"
									:href="dialogImportFolderInfo.folderWebViewLink"
									target="_blank"
									>{{ dialogImportFolderInfo.folderName }}</el-link
								>
							</div>
							<div
								v-for="item in dialogImportFolderInfo.files"
								:key="item.id"
								class="my-3 d-flex justify-content-start"
							>
								<el-link
									class="wrap-text"
									type="primary"
									:href="item.webViewLink"
									target="_blank"
									>{{ item.name }}</el-link
								>
							</div>
						</div>
					</div>
					<div>
						<el-button class="w-25" type="danger" @click="cancelImport()"
							>取消</el-button
						>
						<el-button
							class="w-25"
							type="primary"
							@click="importAllGoogleFile(true)"
							>匯入</el-button
						>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
	</div>

	<el-card v-if="isProgressShow" class="progressBar">
		<template #header>
			<div
				class="card-header d-flex align-items-center justify-content-between"
			>
				<div class="d-flex align-items-center">
					<span class="me-2">匯入進度</span>
					<div class="eyes">
						<i
							v-if="isProgressShowDetail"
							class="fa-solid fa-eye-slash"
							@click="isProgressShowDetail = !isProgressShowDetail"
						></i>
						<i
							v-else
							class="fa-sharp fa-solid fa-eye"
							@click="isProgressShowDetail = !isProgressShowDetail"
						></i>
					</div>
				</div>
				<span
					v-if="isProgressExitShow"
					@click="closeProgressShow()"
					class="NoteBtn NoteBtn-sm me-1"
				>
					<i class="fal fa-times"></i>
				</span>
			</div>
		</template>
		<div class="content" v-if="isProgressShowDetail">
			<div class="mb-3 px-2" v-for="item in progressItems" :key="item">
				{{ item.fileName
				}}<el-progress
					:percentage="item.percentage"
					:status="item.status"
					:format="format(item.percentage)"
					:indeterminate="item.indeterminate"
				/>
			</div>
		</div>
	</el-card>
</template>

<script>
import Editor from "../../../components/Common/Editor.vue";
import TagLeftBar from "../../../components/ChargeMeContactBook/ClassRoom/TagLeftBar_Note.vue";
import { gapi } from "gapi-script";

export default {
	name: "Note",
	components: { Editor, TagLeftBar },
	data() {
		return {
			isSubmiting: false,
			messageList: [],
			lastMessageDate: "",
			scope: ["https://www.googleapis.com/auth/drive.file"],
			pickerApiLoaded: false,
			oauthToken: null,
			progressItems: [],
			isProgressShow: false,
			isProgressShowDetail: true,
			isProgressExitShow: false,
			messageInput: "",
			showTalkWindow: false,
			showSourceBar: false,
			searchTags: [],
			searchPoints: [],
			searchAll: [],
			focusWarning: false,
			usersTags: [],
			selectNote: "",
			dialogAddTag: false,
			tagForm: {
				name: "",
				selected: [],
			},
			assigneeNote: false,
			selectedNoteAssignee: null,
			isReadOnlySelectedNoteAssignee: false,
			noteAssigneeInput: "",
			memberOptions: [],
			todoItemDialog: {
				title: "",
			},
			setEditNoteWin: false,
			noteList: [],
			NoteInfoDialog: false,
			addNoteInfo: {
				id: "",
				title: "",
				bgImg: "",
				bgColor: "",
				// isDatabaseUsed: false,
				isCloudImport: false,
				cloudFolderId: "",
				cloudFolderName: "",
				cloudFolderUrl: "",
				tags: [],
				todoItems: [],
				assignee: [],
				file: [],
			},
			predefineColors: [
				"#FFFFFF",
				"#e6b8af",
				"#f4cccc",
				"#fce5cd",
				"#fff2cc",
				"#d9ead3",
				"#d0e0e3",
				"#c9daf8",
				"#cfe2f3",
				"#d9d2e9",
				"#ead1dc",
			],
			openAssigned: false,
			openNote: false,
			noteTitleInput: "",
			addNote: false,
			classRoomDetails: [],
			knowledgePoints: [],
			knowledgeDialog: false,
			isReadOnlySelectedKnowledgeAssignee: false,
			knowledgeAssigneeInput: "",
			selectedKnowledgeAssignee: null,
			dialogAddKnowledgePointTag: false,
			dialogAddKnowledgePointShare: false,
			dialogAddKnowledgePointShareActiveName: "first",
			knowledgeAssigneeClass: [],
			selectedKnowledgePoint: {},
			knowledgePointTagForm: {
				name: "",
				selected: [],
			},
			dialogImport: false,
			dialogImportFileInfo: [],
			dialogImportFolderInfo: {},
			dialogImportActiveName: "first",
			dialogImportFile: "",
			dialogImportFolder: "",
			cloudImports: [],
			imageUrl: "",
			noneHistory: false,
		};
	},
	mounted() {
		const vm = this;
		vm.initNoteData();
		vm.initMemberData();
		vm.getKnowledgePoints();

		// Load Google APIs
		vm.loadGooglePicker();

		// init usersTags
		this.$store.state.usersTags = [];
		this.$store.state.selectTagId = "All";
	},
	watch: {
		usersTags(val) {
			this.$store.state.usersTags = val;
		},
		dialogAddTag: function () {
			let vm = this;
			if (vm.dialogAddTag) {
				vm.tagForm.selected = vm.addNoteInfo.tags;
			}
		},
		dialogAddKnowledgePointTag: function () {
			let vm = this;
			if (vm.dialogAddKnowledgePointTag) {
				vm.knowledgePointTagForm.selected = vm.selectedKnowledgePoint.tagId;
			}
		},
		"$store.state.selectTagId": function (newVal) {
			console.log(newVal);
			let vm = this;
			vm.initNoteData();
		},
	},
	computed: {
		isEnterpriseView() {
			let vm = this;
			return vm.$route.name == "EnterpriseGPTHome";
		},

		filteredData() {
			return this.noteList
				.filter(
					(x) =>
						x.title.includes(this.$store.state.searchTerm) ||
						x.todoItems.some((todo) =>
							todo.title.includes(this.$store.state.searchTerm)
						)
				)
				.sort((a, b) => (a.isTop ? 1 : b.isTop ? -1 : 0));
		},
		knowledgeAssignee() {
			return this.memberOptions
				.filter((x) =>
					this.selectedKnowledgePoint.sharedMemberId.includes(x.memberId)
				)
				.sort((a, b) => (a.isTop ? 1 : b.isTop ? -1 : 0));
		},
		filteredCloudImports() {
			return (noteId) => {
				return this.cloudImports.filter((x) => x.noteId == noteId);
			};
		},
		cardLimitedItems() {
			return (items) => {
				if (items.length > 15) {
					return items.slice(0, 15);
				} else {
					return items;
				}
			};
		},
		cardLimitedItemsCount() {
			return (items) => {
				if (items.length > 15) {
					return true;
				} else {
					return false;
				}
			};
		},
	},
	methods: {
		collectionsImage(todo) {
			let result = [];
			todo.todoItems.forEach((x) => {
				result = [...result, ...x.fileInfos];
			});
			return result;
		},
		openAddTagDialog() {
			this.dialogAddTag = true;
		},
		async loadHistory() {
			let vm = this;
			await vm
				.fetchAPI(
					"post",
					`${process.env.VUE_APP_URL_API}/Note/GPT/GetHistory`,
					{
						EndDateTime: vm.lastMessageDate,
						Count: 10,
					}
				)
				.then((res) => {
					if (res.data.response.length == 0) {
						vm.$message({
							type: "info",
							message: "已無更早的資料!",
						});
						vm.noneHistory = true;
						return;
					}
					res.data.response.forEach((x) => {
						vm.messageList.unshift({
							id: x.qa_id, //答
							content: x.a_text,
							send: false, // 標記這是發送的訊息
							isHistory: true,
							loading: false, // 這不是loading訊息
							isSaved: false,
						});
						vm.messageList.unshift({
							id: null, //問
							content: x.q_text, // 使用者發送的訊息
							send: true, // 標記這是發送的訊息
							loading: false, // 這不是loading訊息
							isSaved: false,
							isHistory: true,
						});
					});
					// 獲取最後一筆資料
					const lastMessage = res.data.response.slice(-1)[0]; // 或者 vm.messageList[vm.messageList.length - 1];
					vm.lastMessageDate = lastMessage.create_time;
					console.log(vm.lastMessageDate);
				});
		},
		async copyResponse(i) {
			let vm = this;
			try {
				// 假設 i 是要複製的文本
				await navigator.clipboard.writeText(i.content);
				// 這裡可以添加一個提示給用戶，比如使用 alert 或自定義的提示框
				vm.$message({
					type: "success",
					message: "複製成功!",
				});
			} catch (err) {
				console.error("複製失敗:", err);
				alert("複製失敗，請重試。");
			}
		},
		isHelpful(isisHelpful, i) {
			let vm = this;
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/GPT/GPTHelpful`,
				{
					Id: i.id,
					IsHelpful: isisHelpful,
				}
			).then((res) => {
				console.log(res);
				vm.$message({
					type: "success",
					message: "感謝回饋!",
				});
			});
		},
		addNoteFunc_withOutselectTagId(i) {
			const vm = this;
			vm.setEditNoteWin = true;
			let convertedTagId = 0;
			//axios
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/${convertedTagId}`
			)
				.then((res) => {
					var item = res.data;
					vm.addNoteInfo.id = item.noteId;
					vm.addNote = true;

					var newNote = {
						id: item.noteId,
						title: "",
						backgroundColor: "",
						backgroundImage: "",
						// isDatabaseUsed: false,
						isCloudImport: false,
						cloudFolderId: "",
						cloudFolderName: "",
						cloudFolderUrl: "",
						todoItems: [],
						assignees: [],
					};
					if (item.tagId != 0) {
						newNote.tags = [item.tagId];
					} else {
						newNote.tags = [];
					}
					vm.noteList.unshift(newNote);

					setTimeout(() => {
						document.getElementById(`createNote`).focus();
					}, 10);

					vm.$message({
						type: "success",
						message: "新增成功!",
					});
					vm.addNoteInfo.title = "GPT回應" + new Date();
					vm.noteTitleInput = i.content;
					i.isSaved = true;

					vm.inputFirst();
					vm.updateNote();
				})
				.catch((err) => {
					console.log(err.response);
					vm.notify("error", `新增失敗！`);
				});
		},
		typeWriter(message, targetMessage, index, vm, logId) {
			let i = 0;
			targetMessage.content = ""; // 清空content，準備逐字顯示
			let interval = setInterval(() => {
				if (i < message.length) {
					targetMessage.content += message.charAt(i); // 每次增加一個字符
					// 這裡我們要重新更新 messageList 內的對應項目
					vm.messageList.splice(index, 1, { ...targetMessage });
					i++;
				} else {
					clearInterval(interval); // 打字完成後清除計時器
					vm.messageList[index].id = logId; // 這裡更新ID
				}
				this.$nextTick(() => {
					const messageContainer = this.$refs.messageContainer;
					messageContainer.scrollTop = messageContainer.scrollHeight;
				});
			}, 10); // 每個字符的顯示速度，可以根據需要調整
		},
		handlePaste() {
			const items = (event.clipboardData || window.clipboardData).items;
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				if (item.type.startsWith("image/")) {
					const file = item.getAsFile();
					const reader = new FileReader();
					reader.onload = (e) => {
						this.imageUrl = e.target.result; // 更新圖片 URL
					};
					reader.readAsDataURL(file);
				}
			}
		},
		sendMessage() {
			let vm = this;

			if (vm.isSubmiting) {
				vm.notify("info", `等待回應中，請稍後再試`);
				return;
			}
			if ([...vm.searchPoints, ...vm.searchTags, ...vm.searchAll].length == 0) {
				vm.notify("error", `尚未選擇來源`);
				return;
			}
			if (vm.messageInput.trim() == "") {
				vm.notify("error", `尚未輸入訊息`);
				return;
			}

			vm.showSourceBar = false;
			vm.showTalkWindow = true;
			//篩選出選取的知識點Tags
			let knowledgeTags = [
				...new Set(
					vm.knowledgePoints
						.filter((x) => vm.searchPoints.some((y) => x.id === y))
						.map((x) => x.tagId)
						.flat()
				),
			];
			//篩選出選取的知識點Tags
			let searchTags = vm.searchTags;
			const RequestTags = [...new Set([...knowledgeTags, ...searchTags])];

			// 1. 先顯示使用者發送的訊息
			vm.messageList.push({
				id: Date.now(), // 使用唯一的ID
				content: vm.messageInput, // 使用者發送的訊息
				send: true, // 標記這是發送的訊息
				loading: false, // 這不是loading訊息
				isSaved: false,
			});
			// 2. 顯示一個loading氣泡，等待回應
			var loadingMessage = {
				id: Date.now() + 1, // 生成唯一ID
				content: '<i class="fad fa-spinner fa-pulse"></i>', // 顯示 loading 中的提示
				send: false, // 標記這是回應的訊息
				loading: true, // 標記為loading狀態
				isSaved: false,
			};
			vm.messageList.push(loadingMessage); // 顯示loading氣泡

			this.$nextTick(() => {
				const messageContainer = this.$refs.messageContainer;
				messageContainer.scrollTop = messageContainer.scrollHeight;
			});

			let data = {
				TagIds: RequestTags,
				Content: vm.messageInput,
			};
			let apiUrl = `${process.env.VUE_APP_URL_API}/Note/GPT/Request`;
			if (vm.searchAll.length > 0) {
				//如果只有單選不含標籤的文章
				if (vm.searchAll.length == 1 && vm.searchAll.includes("all_noTag")) {
					let RequestArticleIds = this.noteList
						.filter((x) => x.tags.length == 0)
						.map((x) => x.id);
					data = {
						ArticleIds: RequestArticleIds,
						Content: vm.messageInput,
					};
					apiUrl = `${process.env.VUE_APP_URL_API}/Note/GPT/RequestByArticleIds`;
				} else {
					data = {
						TagIds: [],
						Content: vm.messageInput,
					};
				}
			}

			vm.messageInput = "";
			vm.isSubmiting = true;
			vm.fetchAPI("post", apiUrl, data, false)
				.then((res) => {
					// 先找到 messageList 中的對應項目
					let index = vm.messageList.indexOf(loadingMessage);
					if (index !== -1) {
						vm.typeWriter(
							res.data.content,
							loadingMessage,
							index,
							vm,
							res.data.gptLogId
						);
					}
				})
				.catch((err) => {
					// 先找到 messageList 中的對應項目
					let index = vm.messageList.indexOf(loadingMessage);
					vm.notify("error", err);
					vm.typeWriter(
						"回應失敗,請稍後再試!",
						loadingMessage,
						index,
						vm,
						null
					);
				})
				.finally(() => {
					vm.isSubmiting = false;
				});
		},
		// Google picker
		loadGooglePicker() {
			// 动态加载 Google API
			const script = document.createElement("script");
			script.src = "https://apis.google.com/js/api.js";
			script.onload = this.onApiLoad;
			document.body.appendChild(script);
		},
		onApiLoad() {
			// 加载 picker 和 auth2
			gapi.load("client:auth2", this.onAuthApiLoad);
			gapi.load("picker", this.onPickerApiLoad);
		},
		onAuthApiLoad() {
			gapi.client.init({
				clientId: process.env.VUE_APP_GOOGLE_DRIVE_CLIENT_ID,
				scope: this.scope.join(" "),
				plugin_name: process.env.VUE_APP_GOOGLE_PROJECT_ID,
			});
		},
		onPickerApiLoad() {
			this.pickerApiLoaded = true;
		},
		openGooglePicker(isFolder) {
			this.dialogImport = false;
			const authInstance = gapi.auth2.getAuthInstance();
			authInstance.signIn().then((user) => {
				this.oauthToken = user.getAuthResponse().access_token;
				this.createPicker(isFolder);
			});
		},
		createPicker(isFolder) {
			if (this.pickerApiLoaded && this.oauthToken) {
				let picker;
				if (!isFolder) {
					// eslint-disable-next-line no-undef
					picker = new google.picker.PickerBuilder()
						// eslint-disable-next-line no-undef
						.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
						// eslint-disable-next-line no-undef
						.addView(google.picker.ViewId.DOCS)
						.setOAuthToken(this.oauthToken)
						.setDeveloperKey(process.env.VUE_APP_GOOGLE_API_KEY)
						.setCallback(this.pickerCallback)
						.setAppId(process.env.VUE_APP_GOOGLE_PROJECT_ID)
						.build();
				} else {
					// eslint-disable-next-line no-undef
					picker = new google.picker.PickerBuilder()
						.addView(
							// eslint-disable-next-line no-undef
							new google.picker.DocsView(google.picker.ViewId.FOLDERS)
								.setIncludeFolders(true) // 包含文件夹
								.setSelectFolderEnabled(true) // 允许选择文件夹
						)
						.setOAuthToken(this.oauthToken)
						.setDeveloperKey(process.env.VUE_APP_GOOGLE_API_KEY)
						.setCallback(this.pickerCallback)
						.setAppId(process.env.VUE_APP_GOOGLE_PROJECT_ID)
						.build();
				}
				picker.setVisible(true);
			}
		},
		async pickerCallback(data) {
			const vm = this;
			// eslint-disable-next-line no-undef
			if (data.action === google.picker.Action.PICKED) {
				vm.dialogImport = true;
				console.log(data);
				// 在這裡處理選擇的檔案
				if (data.docs[0].type == "document") {
					vm.dialogImportActiveName = "first";
					for (let file of data.docs) {
						vm.dialogImportFile = file.url; // 设置当前文件的 URL
						await vm.importGoogleFile(); // 等待当前文件的导入完成
					}
				} else if (data.docs[0].type == "folder") {
					vm.dialogImportActiveName = "second";
					vm.dialogImportFolder = data.docs[0].url + "?usp=drive_link";
					await vm.importGoogleFolder();
				}
			}
		},
		// Progress Bar
		closeProgressShow() {
			const vm = this;
			vm.isProgressExitShow = false;
			vm.isProgressShow = false;
			vm.isProgressShowDetail = true;
			vm.progressItems = [];
		},
		format(percentage) {
			return percentage === 50 ? "processing" : `${percentage}%`;
		},
		toggleTalkArea() {
			let vm = this;
			vm.showSourceBar = false;
			vm.showTalkWindow = !vm.showTalkWindow;
		},
		//#region start 匯入雲端文件
		async reloadGoogleFiles() {
			const vm = this;
			var files = vm.cloudImports.filter((x) => x.noteId == vm.addNoteInfo.id);

			if (vm.progressItems.length != 0) {
				vm.notify("warning", `請先等待上一個檔案匯入完成後再執行！`);
				return;
			}

			vm.isProgressShow = true;
			vm.isProgressShowDetail = true;
			vm.addNote = false;
			vm.setEditNoteWin = false;
			files.forEach((x) => {
				x.indeterminate = true;
				x.status = "processing";
				x.percentage = 0;
			});
			vm.progressItems = files;

			for (let file of files) {
				var item = vm.progressItems.find((x) => x.fileId == file.fileId);
				item.percentage = 50;
				await vm
					.fetchAPI(
						"post",
						`${process.env.VUE_APP_URL_API}/Note/Cloud/ReloadFile/${file.id}`,
						{},
						false
					)
					.then(() => {
						item.status = "success";
					})
					.catch(() => {
						item.status = "exception";
					})
					.finally(() => {
						item.indeterminate = false;
						item.percentage = 100;
					});
			}

			// 確保所有 API 呼叫完成後再執行 nextTick
			vm.$nextTick(() => {
				vm.initNoteData();
				vm.isProgressExitShow = true;
				vm.notify("success", `檔案重讀作業完成！`);

				setTimeout(() => {
					vm.closeProgressShow();
				}, 3000);
			});
		},
		deleteImportFile(item = null) {
			const vm = this;
			if (item != null) {
				vm.fetchAPI(
					"delete",
					`${process.env.VUE_APP_URL_API}/Note/Cloud/DeleteFile/${item.id}`
				)
					.then(() => {
						vm.cloudImports = vm.cloudImports.filter((x) => x.id != item.id);
						vm.initNoteData();
						vm.$message({
							type: "success",
							message: "刪除完成",
						});
					})
					.catch((err) => {
						console.log(err.response.data);
						vm.notify("error", `刪除失敗！`);
					});
			} else {
				vm.fetchAPI(
					"delete",
					`${process.env.VUE_APP_URL_API}/Note/Cloud/DeleteFolder/${vm.addNoteInfo.id}`
				)
					.then(() => {
						vm.cloudImports = vm.cloudImports.filter(
							(x) => x.noteId != vm.addNoteInfo.id
						);
						vm.addNoteInfo.isCloudImport = false;
						vm.addNoteInfo.cloudFolderId = "";
						vm.addNoteInfo.cloudFolderName = "";
						vm.addNoteInfo.cloudFolderUrl = "";
						vm.initNoteData();
						vm.$message({
							type: "success",
							message: "刪除完成",
						});
					})
					.catch((err) => {
						console.log(err.response.data);
						vm.notify("error", `刪除失敗！`);
					});
			}
		},
		async importAllGoogleFile(isFolder) {
			const vm = this;
			var folderId = "";
			var folderName = "";
			var folderUrl = "";
			var files = [];

			if (isFolder) {
				if (Object.keys(vm.dialogImportFolderInfo).length == 0) {
					vm.notify("warning", "請先讀取Google資料夾！");
					return;
				}
				if (
					vm.cloudImports.some(
						(x) => x.noteId == vm.addNoteInfo.id && x.isBelongFolder == false
					)
				) {
					vm.notify("warning", "請先清空記事本再匯入Google資料夾路徑！");
					return;
				}
				folderId = vm.dialogImportFolderInfo.folderId;
				folderName = vm.dialogImportFolderInfo.folderName;
				folderUrl = vm.dialogImportFolderInfo.folderWebViewLink;
				files = vm.dialogImportFolderInfo.files.map((x) => {
					return {
						fileId: x.id,
						url: x.webViewLink,
						fileName: x.name,
						indeterminate: true,
						status: "processing",
						percentage: 0,
					};
				});
			} else {
				if (vm.dialogImportFileInfo.length == 0) {
					vm.notify("warning", "請先讀取Google文件！");
					return;
				}
				files = vm.dialogImportFileInfo.map((x) => {
					return {
						fileId: x.id,
						url: x.webViewLink,
						fileName: x.title,
						indeterminate: true,
						status: "processing",
						percentage: 0,
					};
				});
			}

			if (vm.progressItems.length != 0) {
				vm.notify("warning", `請先等待上一個檔案匯入完成後再執行！`);
				return;
			}
			await vm.progressBarImport(
				files,
				folderId,
				folderName,
				folderUrl,
				isFolder
			);
		},
		async progressBarImport(
			files,
			folderId = "",
			folderName = "",
			folderUrl = "",
			isFolder
		) {
			const vm = this;
			// Show Progress Bar And Start Import
			vm.isProgressShow = true;
			vm.isProgressShowDetail = true;
			vm.addNote = false;
			vm.setEditNoteWin = false;
			vm.progressItems = files;
			vm.cancelImport();

			for (let file of files) {
				let data = {
					noteId: vm.addNoteInfo.id,
					folderId: folderId,
					folderName: folderName,
					folderUrl: folderUrl,
					isBelongFolder: isFolder,
					fileInfo: [file],
				};

				var item = vm.progressItems.find((x) => x.fileId == file.fileId);
				item.percentage = 50;
				await vm
					.fetchAPI(
						"post",
						`${process.env.VUE_APP_URL_API}/Note/Cloud/ImportFile`,
						data,
						false
					)
					.then(() => {
						item.status = "success";
					})
					.catch((err) => {
						console.log(err.response.data.Msg);
						vm.notify("error", `${err.response.data.Msg}！`);
						item.status = "exception";
					})
					.finally(() => {
						item.indeterminate = false;
						item.percentage = 100;
					});
			}

			// 確保所有 API 呼叫完成後再執行 nextTick
			vm.$nextTick(() => {
				vm.initNoteData();
				vm.isProgressExitShow = true;
				vm.notify("info", `檔案匯入作業完成！`);

				setTimeout(() => {
					vm.closeProgressShow();
				}, 3000);
			});
		},
		cancelImport() {
			const vm = this;
			vm.dialogImport = false;
			vm.dialogImportFile = "";
			vm.dialogImportFolder = "";
			vm.dialogImportFileInfo = [];
			vm.dialogImportFolderInfo = {};
			vm.dialogImportActiveName = "first";
		},
		removeCloudTempFolder() {
			const vm = this;
			vm.dialogImportFolderInfo = {};
		},
		removeCloudTempFile(item) {
			const vm = this;
			vm.dialogImportFileInfo = vm.dialogImportFileInfo.filter(
				(x) => x.id != item.id
			);
		},
		importGoogleFile() {
			const vm = this;
			if (vm.dialogImportFile == "") {
				vm.notify("warning", "請輸入Google文件路徑！");
				return;
			}
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/Cloud/LoadFile`,
				{ url: vm.dialogImportFile }
			)
				.then((res) => {
					console.log(res.data);
					vm.dialogImportFileInfo.push(res.data);
					vm.notify("success", "讀取成功！");
					vm.dialogImportFile = "";
				})
				.catch((err) => {
					console.log(err);
					vm.notify("error", "讀取失敗！");
				});
		},
		importGoogleFolder() {
			const vm = this;
			if (vm.dialogImportFolder == "") {
				vm.notify("warning", "請輸入Google資料夾路徑！");
				return;
			}
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/Cloud/LoadFolder`,
				{ url: vm.dialogImportFolder }
			)
				.then((res) => {
					console.log(res.data);
					vm.dialogImportFolderInfo = {
						folderId: res.data.folderInfo.id,
						folderName: res.data.folderInfo.name,
						folderWebViewLink: res.data.folderInfo.webViewLink,
						files: res.data.fileInfos,
					};
					vm.notify("success", "讀取成功！");
					vm.dialogImportFolder = "";
				})
				.catch((err) => {
					console.log(err);
					vm.notify("error", "讀取失敗！");
				});
		},
		openImportDialog() {
			console.log(this.addNoteInfo);
			if (this.addNoteInfo.todoItems.length != 0) {
				this.notify(
					"warning",
					"請先建立空的記事本，再使用匯入Google檔案功能！"
				);
				return;
			}
			if (this.addNoteInfo.cloudFolderId != "") {
				this.notify("warning", "請先刪除資料夾後，再使用匯入Google檔案功能！");
				return;
			}
			this.dialogImport = true;
		},
		//#region end 匯入雲端文件
		//#region start 知識點管理
		async manageKnowledgeFunc() {
			const vm = this;
			await vm.getKnowledgePoints();
			vm.knowledgeDialog = true;
		},
		getKnowledgePoints() {
			const vm = this;
			vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Note/KnowledgePoint`)
				.then((res) => {
					console.log(res.data);
					vm.knowledgePoints = res.data.knowledgePoints;
					vm.classRoomDetails = res.data.classRooms;
					console.log(vm.knowledgePoints);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		addKnowledge() {
			const vm = this;
			vm.$prompt("請輸入知識點名稱", "新增知識點", {
				confirmButtonText: "新增",
				cancelButtonText: "取消",
			})
				.then(({ value }) => {
					if (vm.knowledgePoints.find((x) => x.title == value) != undefined) {
						vm.$message({
							type: "error",
							message: "知識點名稱重複！",
						});
						return;
					}

					const data = {
						title: value,
					};
					vm.fetchAPI(
						"post",
						`${process.env.VUE_APP_URL_API}/Note/KnowledgePoint`,
						data
					)
						.then((res) => {
							console.log(res.data);
							vm.knowledgePoints.push({
								id: res.data.knowledgePointId,
								title: value,
								isPublic: false,
								tagId: [],
								sharedMemberId: [],
								sharedClassRoomId: [],
							});
							vm.$message({
								type: "success",
								message: "新增完成",
							});
						})
						.catch((err) => {
							console.log(err.response.data);
							vm.notify("error", `新增失敗！`);
						});
				})
				.catch(() => {
					vm.$message({
						type: "info",
						message: "已取消",
					});
				});
		},
		handleSelectKnowledgeAssignee(item) {
			this.selectedKnowledgeAssignee = item;
			this.isReadOnlySelectedKnowledgeAssignee = true;
		},
		cancelKnowledgeAutoComplete() {
			this.knowledgeAssigneeInput = "";
			this.selectedKnowledgeAssignee = null;
			this.isReadOnlySelectedKnowledgeAssignee = false;
		},
		setKnowledgeAssignee() {
			let vm = this;
			this.isReadOnlySelectedKnowledgeAssignee = true;
			vm.selectedKnowledgePoint.sharedMemberId.push(
				vm.selectedKnowledgeAssignee.memberId
			);
			vm.knowledgeAssigneeInput = "";
		},
		deleteKnowledgeAssignee(o) {
			let vm = this;
			vm.selectedKnowledgePoint.sharedMemberId =
				vm.selectedKnowledgePoint.sharedMemberId.filter((x) => {
					return x != o.memberId;
				});
		},
		updateKnowledgeAssignee() {
			const vm = this;
			vm.editKnowledgePoint(vm.selectedKnowledgePoint);
		},
		cancelKnowledgeAssignee() {
			this.selectedKnowledgeAssignee = null;
			this.isReadOnlySelectedKnowledgeAssignee = false;
			this.knowledgeAssigneeInput = "";
		},
		setKnowledgePointObject(item, dialog) {
			const vm = this;
			vm["dialogAddKnowledgePoint" + dialog] = true;
			vm.selectedKnowledgePoint = item;

			if (dialog == "Share") {
				vm.knowledgeAssigneeClass = vm.classRoomDetails.map((x) => {
					return {
						id: x.id,
						name: x.name,
						isChecked: vm.selectedKnowledgePoint.sharedClassRoomId.includes(
							x.id
						),
					};
				});
			}
		},
		setKnowledgePointPublic(item) {
			const vm = this;
			vm.selectedKnowledgePoint = item;
			vm.selectedKnowledgePoint.isPublic = !vm.selectedKnowledgePoint.isPublic;
			vm.editKnowledgePoint(vm.selectedKnowledgePoint);
		},
		selectKnowledgePointTag() {
			const vm = this;
			vm.selectedKnowledgePoint.tagId = vm.knowledgePointTagForm.selected;
			vm.editKnowledgePoint(vm.selectedKnowledgePoint);
		},
		handleCheckKnowledgeChange(item) {
			const vm = this;
			if (item.isChecked) {
				vm.selectedKnowledgePoint.sharedClassRoomId.push(item.id);
			} else {
				vm.selectedKnowledgePoint.sharedClassRoomId =
					vm.selectedKnowledgePoint.sharedClassRoomId.filter(
						(x) => x != item.id
					);
			}
			vm.editKnowledgePoint(vm.selectedKnowledgePoint);
		},
		renameKnowledgePoint(item) {
			const vm = this;
			this.$prompt(`請輸入知識點名稱`, "修改名稱", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				inputValue: item.title,
			})
				.then(({ value }) => {
					if (value == "" || value == null) {
						vm.notify("error", `知識點名稱不可為空白！`);
						return;
					}

					if (vm.knowledgePoints.find((x) => x.title == value) != undefined) {
						vm.$message({
							type: "error",
							message: "知識點名稱重複！",
						});
						return;
					}

					vm.selectedKnowledgePoint = item;
					vm.selectedKnowledgePoint.title = value;
					vm.editKnowledgePoint(vm.selectedKnowledgePoint);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消",
					});
				});
		},
		editKnowledgePoint(item) {
			const vm = this;
			const data = {
				knowledgePointId: item.id,
				title: item.title,
				isPublic: item.isPublic,
				tagId: item.tagId,
				sharedMemberId: item.sharedMemberId,
				sharedClassRoomId: item.sharedClassRoomId,
			};
			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API}/Note/KnowledgePoint`,
				data
			)
				.then(() => {
					var editObj = vm.knowledgePoints.find(
						(x) => x.id == data.knowledgePointId
					);
					editObj.title = data.title;
					editObj.isPublic = data.isPublic;
					editObj.tagId = data.tagId;
					editObj.sharedMemberId = data.sharedMemberId;
					editObj.sharedClassRoomId = data.sharedClassRoomId;

					vm.selectedKnowledgeAssignee = null;
					vm.isReadOnlySelectedKnowledgeAssignee = false;
					vm.knowledgeAssigneeInput = "";

					vm.$message({
						type: "success",
						message: "更新完成",
					});
				})
				.catch((err) => {
					console.log(err.response.data);
					vm.notify("error", `更新失敗！`);
				});
		},
		deleteKnowledgePoint(id) {
			const vm = this;
			this.$confirm(`確定要刪除此知識點嗎?`, "提示", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					vm.fetchAPI(
						"delete",
						`${process.env.VUE_APP_URL_API}/Note/KnowledgePoint/${id}`
					)
						.then(() => {
							vm.knowledgePoints = vm.knowledgePoints.filter((x) => x.id != id);
							vm.$message({
								type: "success",
								message: "刪除完成",
							});
						})
						.catch((err) => {
							console.log(err.response.data);
							vm.notify("error", `刪除失敗！`);
						});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "取消刪除",
					});
				});
		},
		closeKnowledge() {
			const vm = this;
			vm.knowledgeDialog = false;
		},
		//#region end 知識點管理
		copyText(t) {
			navigator.clipboard
				.writeText(t.title)
				.then(() => {
					this.notify("success", "複製成功！");
				})
				.catch(() => {
					this.notify("error", "複製失敗！");
				});
		},
		initMemberData() {
			const vm = this;
			vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/All`)
				.then((res) => {
					vm.memberOptions = [];
					res.data.forEach((x) => {
						vm.memberOptions.push({
							value: vm.getMemberName(x.memberId, x.name),
							memberId: x.memberId,
							name: vm.getMemberName(x.memberId, x.name),
							nickName: x.nickName,
							avatar: x.avatar,
							role: x.role,
							email: x.email,
						});
					});
				})
				.catch((err) => {
					console.log(err);
				});
		},
		uploadFile(param) {
			const vm = this;
			let formData = new FormData();
			formData.append("file", param.file);
			vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/files/file`, formData)
				.then((res) => {
					console.log(res.data);
					const data = {
						noteTodoId: vm.todoItemDialog.id,
						fileName: param.file.name,
						filePath: res.data,
					};
					console.log("1", data);
					// axios;
					vm.fetchAPI(
						"post",
						`${process.env.VUE_APP_URL_API}/Note/NoteTodo/File`,
						data
					)
						.then((res) => {
							var temp = {
								id: res.data,
								name: data.fileName,
								url: data.filePath,
							};
							param.onSuccess(temp);
							console.log(data.fileName, data.filePath);
							vm.$message({
								type: "success",
								message: "檔案上傳成功！",
							});

							let tTodo = vm.addNoteInfo.todoItems.find(
								(x) => x.noteTodoId == vm.todoItemDialog.id
							);
							console.log(tTodo);
							tTodo.file.push(res.data);
							tTodo.fileInfos.push(temp);
							//vm.todoItemDialog.fileList.push(temp);
						})
						.catch((err) => {
							console.log(err.response);
							vm.notify("error", `檔案上傳失敗！`);
						});
				})
				.catch((err) => {
					console.log(err);
					this.notify("error", "檔案上傳失敗！");
				});
		},
		topNote(o) {
			let vm = this;
			o.isTop = !o.isTop;
			const noteId = o.id;
			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API}/Note/SetTop/${noteId}`
			).then(() => {});
		},
		showAllOptions() {
			// 在 focus 時顯示所有選項
			this.$refs.autocomplete.highlight = -1;
			this.$refs.autocomplete.suggestions = this.suggestions;
		},

		querySearchByClassMember(queryString, cb) {
			let vm = this;
			var results = queryString
				? vm.memberOptions.filter(this.createFilter(queryString))
				: vm.memberOptions;

			results = results.filter(
				(item, index, array) =>
					array.findIndex((element) => element.memberId === item.memberId) ===
					index
			);

			var classStudent = vm.addNoteInfo.assignee.map((y) => y.memberId);
			var temp = results.filter((x) => !classStudent.includes(x.memberId));

			cb(temp);
			//   console.log(vm.memberOptions)
			// let uniqueObjects = results.filter((obj, index, array) => {
			//   return array.findIndex((o) => o.id === obj.id) === index;
			// });
			// var temp = uniqueObjects.filter(
			//   (x) => x.memberId != vm.currentUser().id
			// );
		},
		autocomplete_selectAll() {
			let vm = this;
			vm.addNoteInfo.assignee = vm.memberOptions.filter(
				(item, index, array) =>
					array.findIndex((element) => element.memberId === item.memberId) ===
					index
			);
			vm.updateNote();
		},
		createFilter(queryString) {
			return (r) => {
				return (
					r.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ||
					r.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0
				);
			};
		},
		undoNote(o) {
			let vm = this;
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/Undo/${Number(o.id)}`
			)
				.then((res) => {
					console.log(res);
					vm.noteList = vm.noteList.filter((x) => x.id != o.id);
					if (res.data.status == 0) {
						vm.$message({
							type: "success",
							message: `已復原！`,
						});
					} else {
						vm.notify("error", `失敗！`);
					}
				})
				.catch(() => {
					vm.notify("error", `失敗！`);
				});
		},
		deletePermanently(o) {
			let vm = this;
			vm.$confirm("將永久刪除這個記事本, 是否要繼續?", "提示", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					vm.fetchAPI(
						"delete",
						`${process.env.VUE_APP_URL_API}/Note/DeletePermanently/${Number(
							o.id
						)}`
					)
						.then((res) => {
							console.log(res);
							vm.noteList = vm.noteList.filter((x) => x.id != o.id);
							if (res.data.status == 0) {
								vm.$message({
									type: "success",
									message: "删除成功!",
								});
							} else {
								vm.notify("error", `失敗！`);
							}
						})
						.catch(() => {
							vm.notify("error", `失敗！`);
						});
				})
				.catch(() => {
					vm.notify("error", `失敗！`);
				});
		},
		getEditorDataTodoContent(input) {
			let vm = this;
			vm.todoItemDialog.content = input;
		},
		handleNotePreview(file) {
			if (file.response == undefined) {
				window.open(file.url);
			} else {
				window.open(file.response.url);
			}
		},
		handleNoteDownload(file) {
			if (file["response"] != undefined) file = file.response;
			this.axios
				.get(file.url, { responseType: "blob" })
				.then((response) => {
					const blob = new Blob([response.data], { type: response.data.type });
					const link = document.createElement("a");
					link.href = URL.createObjectURL(blob);
					link.download = file.name;
					link.click();
					URL.revokeObjectURL(link.href);
				})
				.catch(console.error);
		},
		handleNoteRemove(file) {
			console.log(file);
			let fileId = "";
			if (file.response == undefined) {
				fileId = file.id;
			} else {
				fileId = file.response.id;
			}
			let vm = this;
			console.log(fileId);
			//axios
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Note/NoteTodo/${
					vm.todoItemDialog.id
				}/File/${Number(fileId)}`
			)
				.then(() => {
					this.$message({
						type: "success",
						message: "刪除成功",
					});
					let tTodo = vm.addNoteInfo.todoItems.find(
						(x) => x.noteTodoId == vm.todoItemDialog.id
					);
					//拿掉一個
					tTodo.file.shift();
					// vm.todoItemDialog.fileInfos = vm.todoItemDialog.fileInfos.filter(
					// 	(x) => x.id != fileId
					// );
				})
				.catch((err) => {
					console.log(err);
					vm.notify("error", "刪除失敗！");
				});
		},
		deleteTodoAssignee(o) {
			let vm = this;
			vm.addNoteInfo.assignee = vm.addNoteInfo.assignee.filter((x) => {
				return x.memberId != o.memberId;
			});
		},
		handleSelectNoteAssignee(item) {
			this.selectedNoteAssignee = item;
			this.isReadOnlySelectedNoteAssignee = true;
		},
		cancelNoteAutoComplete() {
			if (this.noteAssigneeInput == "") this.assigneeNote = false;
			this.selectedNoteAssignee = null;
			this.isReadOnlySelectedNoteAssignee = false;
			this.noteAssigneeInput = "";
		},
		setNoteAssignee() {
			let vm = this;
			this.isReadOnlySelectedNoteAssignee = true;
			vm.addNoteInfo.assignee.push(vm.selectedNoteAssignee);
			vm.noteAssigneeInput = "";
		},
		saveTodoItemContentById(id) {
			let vm = this;
			console.log(vm.todoItemDialog.content);
			let data = {
				Id: id,
				Content: vm.todoItemDialog.content,
			};
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/EditNoteTodoContent`,
				data
			).then(() => {
				let tTodo = vm.addNoteInfo.todoItems.find((x) => x.noteTodoId == id);
				tTodo.content = vm.todoItemDialog.content;
			});
		},
		getTodoItemById(id) {
			let vm = this;
			vm.fetchAPI(
				"get",
				`${process.env.VUE_APP_URL_API}/Note/NoteTodoById/${id}`
			).then((res) => {
				console.log(res);
				vm.todoItemDialog.dueDate = res.data.dueDate;
				vm.todoItemDialog.content = res.data.content;
				vm.todoItemDialog.studentAssignList = res.data.assignee;
				vm.todoItemDialog.fileList = res.data.fileList;
			});
		},
		setTodoItemDialog(o) {
			let vm = this;
			vm.NoteInfoDialog = true;
			vm.todoItemDialog.id = o.noteTodoId;
			vm.todoItemDialog.title = o.title;
			console.log(o);
		},
		deleteTodoItem(o) {
			let vm = this;
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Note/NoteTodo/${o.noteTodoId}`
			).then((res) => {
				console.log(res);
				vm.addNoteInfo.todoItems = vm.addNoteInfo.todoItems.filter((x) => {
					return x != o;
				});
			});
		},
		setEditNote(o) {
			let vm = this;
			if (vm.addNote) {
				vm.focusWarning = true;
				return;
			}

			vm.setEditNoteWin = true;
			vm.assigneeNote = false;
			vm.addNoteInfo.assignee = o.assignees;
			vm.addNoteInfo.id = o.id;
			vm.addNoteInfo.title = o.title;
			// vm.addNoteInfo.isDatabaseUsed = o.isDatabaseUsed;
			vm.addNoteInfo.isCloudImport = o.isCloudImport;
			vm.addNoteInfo.cloudFolderId = o.cloudFolderId;
			vm.addNoteInfo.cloudFolderName = o.cloudFolderName;
			vm.addNoteInfo.cloudFolderUrl = o.cloudFolderUrl;
			vm.addNoteInfo.bgColor = o.backgroundColor;
			vm.addNoteInfo.bgImg = o.backgroundImage;
			vm.addNoteInfo.todoItems = o.todoItems;
			vm.addNoteInfo.tags = o.tags;
			vm.addNote = true;
		},
		resetNoteModel() {
			let vm = this;

			if (vm.noteList.length == 0) {
				vm.addNoteInfo = {
					id: "",
					title: "",
					bgImg: "",
					bgColor: "",
					// isDatabaseUsed: false,
					isCloudImport: false,
					cloudFolderId: "",
					cloudFolderName: "",
					cloudFolderUrl: "",
					todoItems: [],
					assignee: [],
				};
				vm.addNote = false;
				vm.setEditNoteWin = false;
				return;
			}

			// Update IsDatabaseUsed
			// if (vm.addNoteInfo.isDatabaseUsed) {
			var data = {
				noteId: vm.addNoteInfo.id,
			};

			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API}/Note/SplitTag`,
				data,
				false
			)
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
			// }

			let note = vm.noteList.find((x) => x.id == vm.addNoteInfo.id);
			note.id = vm.addNoteInfo.id;
			note.title = vm.addNoteInfo.title;
			note.backgroundColor = vm.addNoteInfo.bgColor;
			note.backgroundImage = vm.addNoteInfo.bgImg;
			// note.isDatabaseUsed = vm.addNoteInfo.isDatabaseUsed;
			note.todoItems = vm.addNoteInfo.todoItems;

			vm.addNoteInfo = {
				id: "",
				title: "",
				bgImg: "",
				bgColor: "",
				// isDatabaseUsed: false,
				isCloudImport: false,
				cloudFolderId: "",
				cloudFolderName: "",
				cloudFolderUrl: "",
				todoItems: [],
				assignee: [],
			};
			vm.noteTitleInput == "";
			vm.focusWarning = false;
		},
		async initNoteData() {
			const vm = this;
			let apiPath = `${process.env.VUE_APP_URL_API}/Note`;
			if (vm.$store.state.selectTagId == "trash") {
				apiPath = `${process.env.VUE_APP_URL_API}/Note/Deleted`;
			}

			vm.fetchAPI("get", apiPath).then((res) => {
				console.log(res);
				vm.noteList = res.data.noteResponses;
				vm.cloudImports = res.data.cloudImports;
				if (
					vm.$store.state.selectTagId != "trash" &&
					vm.$store.state.selectTagId != "All" &&
					vm.$store.state.selectTagId != "AllWithoutTag"
				) {
					// vm.noteList = vm.noteList.filter(
					// 	(x) => x.tags.indexOf(vm.$store.state.selectTagId) != -1
					// );
					vm.noteList = vm.noteList.filter((x) =>
						vm.$store.state.selectTagId.some(
							(tagId) => x.tags.indexOf(tagId) != -1
						)
					);
					console.log(vm.noteList);
				} else if (vm.$store.state.selectTagId == "AllWithoutTag") {
					vm.noteList = vm.noteList.filter((x) => x.tags.length == 0);
				}
			});

			vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Note/UserTag`)
				.then((res) => {
					vm.usersTags = res.data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		updateNote() {
			const vm = this;
			vm.openNote = false;
			let data = {
				NoteId: vm.addNoteInfo.id,
				NoteBgImg: null,
				NoteBgColor: vm.addNoteInfo.bgColor,
				// isDatabaseUsed: vm.addNoteInfo.isDatabaseUsed,
				Title: vm.addNoteInfo.title,
				NoteAssignee: vm.addNoteInfo.assignee.map((x) => {
					return x.memberId;
				}),
			};
			vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Note/EditNote`, data)
				.then((res) => {
					console.log(res);
					let note = vm.noteList.find((x) => x.id == vm.addNoteInfo.id);

					note.assignees = vm.addNoteInfo.assignee;
					vm.selectedNoteAssignee = null;
					vm.isReadOnlySelectedNoteAssignee = false;
					vm.noteAssigneeInput = "";
				})
				.catch((e) => {
					console.log(e);
				});
		},
		deleteNote(o) {
			console.log(o);
			let vm = this;
			vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/Note/${o.id}`).then(
				(res) => {
					console.log(res);

					vm.noteList = vm.noteList.filter((x) => {
						return x.id != o.id;
					});
					vm.addNoteInfo = {
						id: "",
						title: "",
						bgImg: "",
						bgColor: "",
						// isDatabaseUsed: false,
						isCloudImport: false,
						cloudFolderId: "",
						cloudFolderName: "",
						cloudFolderUrl: "",
						todoItems: [],
						assignee: [],
					};
					vm.$message({
						type: "success",
						message: "刪除成功！",
					});
					vm.addNote = false;
					vm.setEditNoteWin = false;
				}
			);
		},
		inputFirst() {
			let vm = this;
			if (vm.noteTitleInput == "") return;
			let data = {
				NoteId: vm.addNoteInfo.id,
				Title: vm.noteTitleInput,
			};
			vm.noteTitleInput = "";
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/NoteTodo`,
				data,
				false
			)
				.then((res) => {
					var item = res.data;
					vm.addNoteInfo.todoItems.push({
						noteTodoId: item.noteTodoId,
						title: data.Title,
						checked: item.checked,
						file: null,
						link: null,
						content: "",
					});
					setTimeout(() => {
						document.getElementById(`todo_${item.noteTodoId}`).focus();
					}, 6);
				})
				.catch((err) => {
					console.log(err.response);
					vm.notify("error", `新增失敗！`);
				});
		},
		addNoteFunc() {
			const vm = this;
			vm.setEditNoteWin = true;
			let convertedTagId = isNaN(Number(vm.$store.state.selectTagId))
				? 0
				: Number(vm.$store.state.selectTagId);

			//axios
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/${convertedTagId}`
			)
				.then((res) => {
					var item = res.data;
					vm.addNoteInfo.id = item.noteId;
					vm.addNote = true;

					var newNote = {
						id: item.noteId,
						title: "",
						backgroundColor: "",
						backgroundImage: "",
						// isDatabaseUsed: false,
						isCloudImport: false,
						cloudFolderId: "",
						cloudFolderName: "",
						cloudFolderUrl: "",
						todoItems: [],
						assignees: [],
					};
					if (item.tagId != 0) {
						newNote.tags = [item.tagId];
					} else {
						newNote.tags = [];
					}
					vm.noteList.unshift(newNote);

					setTimeout(() => {
						document.getElementById(`createNote`).focus();
					}, 10);
					vm.$message({
						type: "success",
						message: "新增成功!",
					});
				})
				.catch((err) => {
					console.log(err.response);
					vm.notify("error", `新增失敗！`);
				});
		},
		updateNoteTodo(o) {
			let vm = this;
			console.log(o);
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Note/EditNoteTodo`,
				o,
				false
			)
				.then((res) => {
					console.log(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		addTag(tagName) {
			let vm = this;

			if (vm.usersTags.find((x) => x.name == tagName) != undefined) {
				vm.$message({
					type: "error",
					message: "標籤名稱重複！",
				});
				return;
			}

			if (tagName != "") {
				const data = {
					tagName: tagName,
				};
				vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Note/NoteTag`, data)
					.then((res) => {
						vm.$message({
							type: "success",
							message: "新增成功",
						});
						let insertData = { id: res.data.tagId, name: tagName };
						vm.usersTags.unshift(insertData);
					})
					.catch((err) => {
						console.log(err);
					});
				vm.tagForm.name = "";
				vm.knowledgePointTagForm.name = "";
			} else {
				vm.$message({
					type: "error",
					message: "請輸入標題！",
				});
			}
		},
		getUserTagsName(item) {
			if (this.usersTags.find((x) => x.id == item) == undefined) return;
			return this.usersTags.length == 0
				? ""
				: this.usersTags.find((x) => x.id == item).name;
		},
		truncateText(text, number) {
			if (text.length > number) {
				return text.slice(0, number - 3) + "...";
			} else {
				return text;
			}
		},
		selectTag() {
			let vm = this;
			let o = vm.selectNote;

			const data = {
				noteId: o.id,
				tagIds: vm.tagForm.selected,
			};

			vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/Note/NoteTag`, data)
				.then(() => {
					vm.noteList.find((x) => x.id == o.id).tags = vm.tagForm.selected;
					vm.addNoteInfo.tags = vm.tagForm.selected;
					if (
						vm.$store.state.selectTagId != "trash" &&
						vm.$store.state.selectTagId != "" &&
						vm.$store.state.selectTagId != "All"
					)
						vm.noteList = vm.noteList.filter((x) =>
							vm.$store.state.selectTagId.some(
								(tagId) => x.tags.indexOf(tagId) != -1
							)
						);
					vm.$message({
						type: "success",
						message: "更新成功",
					});
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@keyframes scaleAnimation {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}
.talkArea {
	position: fixed;
	backdrop-filter: blur(10px);
	background-color: rgba(0, 0, 0, 0.7);
	top: 50px;
	bottom: 0;
	left: 250px;
	right: 0;
	margin: auto;
	z-index: 8;
	padding-bottom: 180px;
}
.messageBox {
	position: fixed;
	padding: 20px;
	background-color: white;
	border-radius: 10px 10px 0 0;
	bottom: 0;
	left: 250px;
	right: 0px;
	margin: auto;
	z-index: 9;
	max-width: 700px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 3px 3px rgba(0, 0, 0, 0.15);
}
.messageBoxMobile {
	background-color: white;
	padding: 10px;
	width: 97vw;
	left: 0;
	box-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}
.message-main {
	display: flex;
	align-items: center;
	background-color: #cccccc;
	border-radius: 10px;
	.message-input {
		border-radius: 10px;
		padding: 20px 10px;
		background-color: #f5f5f5;
		display: flex;
		.message-input-textarea {
			border: none;
			outline: none;
			box-shadow: none;
			background-color: rgba(0, 0, 0, 0);
			.el-textarea__inner {
				background-color: rgba(0, 0, 0, 0) !important;
			}
		}
		.message-input-textarea:focus,
		.message-input-textarea:active {
			border: none;
			outline: none;
			box-shadow: none;
			background-color: rgba(0, 0, 0, 0);
		}
	}
}

.noteTopSection {
	.fa-thumbtack {
		text-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.2);
	}
	.fa-thumbtack {
		cursor: pointer;
		transform: rotate(45deg);
		border-radius: 99em;

		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 10px;
		text-align: center;
		background-color: $main_color;
	}
	.fa-sparkle {
		border-radius: 99em;
		width: 25px;
		height: 25px;
		line-height: 25px;
		font-size: x-small;
		text-align: center;
		background-color: #1fbf42c4;
	}
}
.focusWarning {
	transition: transform 0.3s ease;
	animation: scaleAnimation 0.2s forwards;
	border: 1px solid rgb(255, 168, 168);
}
.divBg {
	background-color: rgba(0, 0, 0, 0);
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 89;
}
.EditWin {
	z-index: 99;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: auto;
	width: 600px;
}
.EditWinOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 80;
}
.circleDiv {
	width: 2.25rem;
	height: 2.25rem;
	border-radius: 99em;
	background-color: red;
	border: 1px solid rgba(0, 0, 0, 0.15);
	cursor: pointer;
}
.circleDiv:hover {
	border: 2px solid rgba(0, 0, 0, 0.55);
}
:deep .el-card__body {
	padding: 10px;
	.title {
		min-height: 2.5rem;
	}
}
.AddNoteTitle {
	max-height: 500px;
	overflow-y: auto;
}
.AddNoteTitleInput {
	border: none;
	padding: 0.5em 0;
	width: 100%;
}
.pre-wrap {
	white-space: pre-wrap;
}
.wrap-text {
	font-size: 14px;
	line-height: 1.5;
	word-break: break-word;
	white-space: pre-wrap; /* 保留換行符並進行換行 */
	display: -webkit-box; /* 必須，為了啟用 line-clamp */
	-webkit-box-orient: vertical; /* 垂直方向排列的盒模型 */
	-webkit-line-clamp: 5; /* 最多顯示 4 行 */
	overflow: hidden; /* 隱藏超出的內容 */
}
:deep .el-textarea {
	textarea {
		border: none;
		padding: 0.5em 0;
		width: 100%;
		box-shadow: none !important;
		overflow: hidden;
		&:focus {
			outline: none;
			background-color: #f4f4f4;
		}
	}
	&.mobile textarea {
		// max-height: 300px;
		overflow-y: auto;
	}
}
.NoteCheckBox {
	padding: 0.25rem 0;
	border-top: 1px solid rgba(0, 0, 0, 0.3);
}
.note_btn_area {
	background-color: white;
	padding: 0.5rem;
}
input[type="text"],
textarea {
	background-color: rgba(0, 0, 0, 0);
}
.AddNoteTitleInput:focus {
	outline: none;
	background-color: #f4f4f4;
}
.AddNoteArea {
	cursor: text;
	background-size: cover;
	::v-deep {
		.el-card__body {
			padding: 0;
		}
	}
}
.NoteBtn {
	height: 30px;
	width: 30px;
	border-radius: 99em;
	cursor: pointer;
	box-sizing: border-box;
	display: inline-block;
	text-align: center;
	color: #5f6368;
	border: 1px solid rgba(0, 0, 0, 0.15);
	&.isDatabaseUsed {
		background-color: #1fbf42c4;
		i {
			color: #000;
		}
	}
}
.NoteBtn-sm {
	box-sizing: border-box;
	display: block;

	width: 24px !important;
	height: 24px;
	line-height: 24px;
	font-size: 12px;
}
.NoteBtn:hover {
	background-color: #eaeaea;
	&.isDatabaseUsed {
		background-color: #1fbf42c4;
		i {
			color: #000;
		}
	}
}
.fa-user-plus {
	cursor: pointer;
	transition: 0.1s;
}
.fa-times {
	line-height: 1.8;
}
.add_contact {
	background-color: $main_color;
	color: #77724b;
	border: 1px solid #77724b;
}
.add_knowledge {
	background-color: #74c0fc;
	color: #77724b;
	border: 1px solid #77724b;
}
.tagsArea {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	:deep .el-tag {
		border: none !important;
		background-color: #398d69 !important;
		color: #fff;
		margin-left: 0.5rem;
		margin-bottom: 0.25rem !important;
	}
}
.removeFileIcon,
.downloadFileIcon {
	cursor: pointer;
	&:hover {
		color: #2c2c2c;
	}
}
.colorCircle {
	width: 20px;
	height: 20px;
	border: 1px solid rgba(0, 0, 0, 0.15);
}
.dialog :deep .el-dialog {
	.el-dialog__header {
		margin-right: 0 !important;
	}
	.isPublic {
		background-color: #74c0fc !important;
	}
	.knowledgePointDialogCollapse .el-collapse {
		min-height: 35vh;
		max-height: 50vh;
		overflow-y: auto;
	}
}
.dialog :deep .el-collapse {
	.el-collapse-item__content {
		padding-bottom: 0.5rem;
	}
	.el-tooltip__trigger {
		height: 24px;
	}
	.el-collapse-item:last-child {
		.el-collapse-item__header {
			border: none;
		}
	}
}
:deep .el-link {
	font-size: 16px;
}
.messageRow {
	margin: auto;
	max-width: 700px;
	padding: 1rem;
	overflow-y: auto;
	height: 100%;
	.messagePopup {
		max-width: 300px;
		margin: 5px 0;
		word-break: break-all;
		line-height: 1.5;
		box-shadow: 2px 2px rgba(0, 0, 0, 0.4);
		color: white;
		display: inline-block;
		padding: 0.75rem;
		border-radius: 8px;
		background-color: black;
		text-align: start;
	}
}

/* 定義進出動畫 */
.slide-up-enter-active,
.slide-up-leave-active {
	transition: all 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to /* 定義離開時的最終狀態 */ {
	transform: translateY(100%);
	opacity: 0;
}

.progressBar {
	text-align: start;
	position: fixed;
	right: 1rem;
	bottom: 10rem;
	z-index: 10000;
	width: 60%;
	.eyes {
		cursor: pointer;
		color: #000;
		&:hover {
			color: #2c2c2c;
		}
	}
	.content {
		max-height: 30vh;
		overflow-y: auto;
	}
}
:deep .el-progress {
	.el-progress__text {
		min-width: unset !important;
	}
}
@media screen and (min-width: 768px) {
	.progressBar {
		right: 1rem;
		width: 30%;
	}
}

.leftMargin {
	margin-left: 250px;
}
.image-preview {
	width: 50px;
	border-radius: 8px;
	overflow: hidden;
	height: 50px;
	position: relative; /* 使子元素絕對定位 */
	margin-bottom: 0.5rem;
}
.image-preview img {
	width: 100%; /* 設置圖片寬度為 100% */
	height: 100%; /* 設置圖片高度為 100% */
	object-fit: cover; /* 保持圖片比例並填滿容器 */
	position: absolute; /* 絕對定位以填滿容器 */
	top: 0;
	left: 0;
}
.image-preview .deleteImg {
	position: absolute;
	right: 2px;
	top: 2px;
	z-index: 11;
	width: 20px;
	line-height: 20px;
	height: 20px;
}

::v-deep .el-upload--picture-card {
	width: 80px;
	height: 80px;
	line-height: 80px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
	width: 80px !important;
	height: 80px !important;
}
</style>
